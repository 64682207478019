::-webkit-scrollbar {
    width: .4rem
}

::-webkit-scrollbar-thumb {
    background: #F2F2F2;
}

::-webkit-scrollbar-track {
    background: white;
}